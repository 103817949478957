import axios from "axios";
import { Message } from "element-ui";

const request = axios.create();
request.defaults.baseURL = process.env.VUE_APP_BASEURL;

const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");
if (token) request.defaults.headers.token = token;

request.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");
    if (token) config.headers.token = token;
    return config;
  },
  function(error) {
    Message({
      message: error,
      showClose: true,
      type: "warning",
    });
    return false;
  }
);

request.interceptors.response.use(
  function(response) {
    if (response.status === 200) {
      return response.data;
    } else {
      Message({
        message: response.data.message,
        showClose: true,
        type: "warning",
      });
      return false;
    }
  },
  function(error) {
    Message({
      message: error,
      showClose: true,
      type: "warning",
    });
    console.error(error);
    return false;
  }
);

export default request;
