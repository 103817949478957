<template>
    <div class="fromItem">
        <el-form :inline="true" ref="searchForm" :model="formInline" class="demo-form-inline">
            <el-form-item
                    v-for="(item,index) in formList"
                    :label="item.el == 'el-input' ? item.labelT : item.label"
                    :key="index" style="margin-bottom: 0px;"
                    :style="item.width"
                    :class="[item.align ? 'right' : '']">
                <template v-if="item.el === 'el-input'">
                    <el-input v-model="formInline[item.props]" :placeholder="item.placeholder" class="custom-input"></el-input>
                </template>
                <template v-if="item.el === 'el-select'">
                    <el-select v-model="formInline[item.props]" :placeholder="item.placeholder"  class="custom-select">
                        <el-option
                          v-for="itemO in item.options"
                          :key="itemO.value"
                          :label="itemO.label"
                          :value="itemO.value">
                        </el-option>
                    </el-select>
                </template>
                <template v-if="item.el === 'el-date-picker'">
                    <el-date-picker
                            class="custom-datepicker"
                            v-model=" formInline[item.props]"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            @change="dateChangebirthday"
                            format='yyyy-MM-dd'
                            :default-time="['12:00:00']">
                    </el-date-picker>
                </template>
                <template v-if="item.el === 'el-datetime'">
                    <el-date-picker
                    class="custom-datepicker"
                      v-model="formInline[item.props]"
                      type="datetime"
                      value-format="yyyy-MM-dd"
                      format='yyyy-MM-dd'
                      placeholder="选择日期时间">
                    </el-date-picker>
                </template>
                <template v-if="item.el === 'el-radio-group'">
                    <el-radio-group v-model="formInline[item.props]" @change="onChangeRadio" class="custom-radio-group-box">
                        <el-radio-button class="custom-radio-group" v-for="(radio,index) in item.options" :label="radio.value" :key="index">{{radio.name}}</el-radio-button>
                    </el-radio-group>
                </template>
            </el-form-item>
            <el-form-item style="margin-bottom: 0px">
                <el-button type="primary" v-if="btn.includes('search') && btnObj.search" @click="onSearch" class="btn item-primary">{{btnObj.search}}</el-button>
                <el-button type="primary" v-if="btn.includes('add') && btnObj.add" @click="onAdd" class="btn item-warning">{{btnObj.add}}</el-button>
                <el-button type="primary" v-if="btn.includes('export') && btnObj.export" @click="onExport" class="btn item-export">{{btnObj.export}}</el-button>
                <el-button type="primary" v-if="btn.includes('reset') && btnObj.reset" @click="onReset" class="btn item-info">{{btnObj.reset}}</el-button>
            </el-form-item>
        </el-form>
        <slot name="right"></slot>
    </div>
</template>

<script>
export default {
  name: "FormItem",
  data(){
    return {
      formInline:{
       
      },
      searchObj:{},
      time: ["2021-06-01","2021-06-01"]
    }
  },
  props:{
    search:{
      type:Object,
      default(){
        return {}
      }
    },
    formList:{
      type:Array,
      default(){
        return []
      }
    },
    btn:{
      type:String,
      default(){
        return ''
      }
    },
    btnObj:{
      type:Object,
      default(){
        return {}
      }
    },
    Dateselection: {
      type:Object,
      
    }
  },
  created() {
     console.log(this.Dateselection)

  },
  mounted() {
   
    this.formInline = Object.assign({},{...this.search})
   console.log(this.formInline)
    this.searchObj = Object.assign({},this.search)

  },
  
  methods:{
    onSearch(){
      console.log(this.formInline)
      this.$emit('search',this.formInline)
    },
    dateChangebirthday(val){
      console.log(val)

    },
    onChangeRadio(){
      this.$emit('changeRadio',this.formInline)
    },
    onAdd(){
      this.$emit('add','')
    },
    onExport(){
      this.$emit('export','')
    },
    onReset(){
      // let params = Object.keys(this.formInline).forEach(key => (this.formInline[key] = ""));
      for (let i in this.formList){
        if (this.formList[i].noClear){
          this.$set(this.formInline,this.formList[i].props,this.searchObj[this.formList[i].props])
        }else{
          this.$set(this.formInline,this.formList[i].props,'')
        }
      }
      this.$emit('reset',this.formInline)
    }
  }
}
</script>
<style lang="scss" scoped>
.fromItem{
    padding: 20px;
    position: relative;
    .right{
        position: absolute;
        right: 0;
    }
    .btn{
      font-weight: 500;
        border: none;
    }
}
.custom-select {
  margin-top: 20px;
}
</style>
