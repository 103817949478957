import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../request";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import("../components/Index.vue"),
        children: [
            {
                name: "数据首页",
                path: "",
                component: () => import("../components/Home.vue"),
            },
            {
                name: "slimhome",//极简首页
                path: "/slimhome",
                component: () => import("../components/SlimHome.vue"),
            },
            {
                name: "page",//导航页面
                path: "/page",
                component: () => import("../components/Page.vue"),
                children: [
                    {
                        name: "washroomlist",//公厕列表
                        path: "/washroomlist",
                        redirect: "/WashroomSummarizing",
                    },
                    {
                        name: "washroomSummarizing",//公厕列表汇总
                        path: "/washroomSummarizing",
                        component: () => import("../components/WashroomSummarizing.vue"),
                    },
                    {
                        name: "washroomlist",//公厕详情
                        path: "/washroomlist/:name",
                        component: () => import("../components/Washroom.vue"),
                        props: true,
                    },
                    {
                        name: "stationlist",//转运站列表
                        path: "/stationlist",
                        redirect: "/stationlist/1",
                    },
                    {
                        name: "stationlist",//转运站详情
                        path: "/stationlist/:name",
                        component: () => import("../components/Station.vue"),
                        props: true,
                    },
                    {
                        name: "archives",//档案管理
                        path: "/archives",
                        redirect: "/washroomarchives",
                    },
                    {
                        name: "washroomarchives",//公厕档案管理
                        path: "/washroomarchives",
                        component: () => import("../components/WashroomArchives.vue"),
                    },
                    {
                        name: "washroomRepairQuote",//公厕报修管理
                        path: "/washroomRepairQuote",
                        component: () => import("../components/WashroomRepairQuote.vue"),
                    },
                    {
                        name: "maintenanceStatistical",//公厕维修统计
                        path: "/maintenanceStatistical",
                        component: () => import("../components/MaintenanceStatistical.vue"),
                    },
                    {
                        name: "stationarchives",//转运站档案管理
                        path: "/stationarchives",
                        component: () => import("../components/StationArchives.vue"),
                    },
                    {
                        name: "projectarchives",//项目部档案管理
                        path: "/projectarchives",
                        component: () => import("../components/ProjectArchives.vue"),
                    },
                    {
                        name: "personnelarchives",//人员档案管理
                        path: "/personnelarchives",
                        component: () => import("../components/PersonnelArchives.vue"),
                    },
                    {
                        name: "videoEquipment",//视频设备管理
                        path: "/videoEquipment",
                        component: () => import("../components/VideoEquipment.vue"),
                    },
                    {
                        name: "statistics",//统计分析
                        path: "/statistics",
                        redirect: "/washroomscore",
                    },
                    {
                        name: "washroomscore",//公厕绩效考核
                        path: "/washroomscore",
                        component: () => import("../components/Washroomscore.vue"),
                    },
                    {
                        name: "stationscore",//转运站绩效考核
                        path: "/stationscore",
                        component: () => import("../components/Stationscore.vue"),
                    },
                    {
                        name: "personnelscore",//项目部绩效统计
                        path: "/personnelscore",
                        component: () => import("../components/Personnelscore.vue"),
                    },
                    {
                        name: "alarmrecord",//报警记录
                        path: "/alarmrecord",
                        component: () => import("../components/AlarmRecord.vue"),
                    },
                    {
                        name: "Permission",///权限管理
                        path: "/Permission",
                        redirect: "/usermanage",
                    },
                    {
                        name: "usermanage",//用户管理
                        path: "/usermanage",
                        component: () => import("../components/Permissions.vue"),
                    },
                    {
                        name: "rolemanage",//角色管理
                        path: "/rolemanage",
                        component: () => import("../components/RoleManagement.vue"),
                    },
                    {
                        name: "pathview",//轨迹查看
                        path: "/pathview",
                        component: () => import("../components/PathView.vue"),
                    },
                    {
                        name: "log",//操作日志
                        path: "/log",
                        component: () => import("../components/Log.vue"),
                    },
                ],
            },
        ],
    },
    {
        name: "大数据页面",
        path: "/dataview",
        component: () => import("../components/DataView.vue"),
    },
    {
        name: "登录",
        path: "/login",
        component: () => import("../components/Login.vue"),
    },
    {
        name: "404",
        path: "*",
        component: () => import("../components/404.vue"),
    },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        next();
    } else {
        const session = sessionStorage.getItem("token") ?? localStorage.getItem("token");
        session ? next() : next("/login");
    }
});

export default router;
