import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import App from "./App.vue";
import "./assets/css/custom.scss";
import customUi from "./components/pubilc/index.js";
import exports from "./request/exports";
import echarts from 'echarts'
import BaiduMap from 'vue-baidu-map';
import  EZUIKit from 'ezuikit-js';
import variable from "./utils/variable";


Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '6pVSdLLnNEBwpgHl9VjcaNqA32Ph6iMg'
  })

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(customUi);
Vue.prototype.$export = exports;
Vue.prototype.$eventbus = new Vue();
Vue.prototype.$ezuikit = EZUIKit;
Vue.prototype.websocketUrl = variable
new Vue({
    render: h => h(App),
    router,
}).$mount("#app");
